<template>
  <div class="order">
    <div class="order-herder">
      <el-form :model="form" ref="form" size="small" inline>
        <el-form-item label="订单类型：" prop="orderNumber">
          <el-input v-model="form.orderNumber" placeholder="请输入订单编号" clearable></el-input>
        </el-form-item>
        <el-form-item label="订单编号：" prop="orderNumber">
          <el-input v-model="form.orderNumber" placeholder="请输入订单编号" clearable></el-input>
        </el-form-item>
        <el-form-item label="商品名称：" prop="lesseeName">
          <el-input v-model="form.lesseeName" placeholder="请输入承租人全称" clearable></el-input>
        </el-form-item>
        <el-form-item label="下单时间：" prop="platformLevel">
          <el-date-picker v-model="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" clearable type="daterange" align="left" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="info" plain @click="onReset">重 置</el-button>
          <el-button type="primary" @click="onSearch">查 询</el-button>
          <el-button type="primary" @click="handleExport">导出账单</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="order-menu">
      <el-radio-group v-model="form.orderQueryState" size="small" @change="onReset">
        <el-radio-button label="0">全部 ({{ numbersData.all || 0 }})</el-radio-button>
        <el-radio-button label="1">待审核 ({{ numbersData.pendingReview || 0 }})</el-radio-button>
        <el-radio-button label="2">待发货 ({{ numbersData.pendingShipment || 0 }})</el-radio-button>
        <el-radio-button label="3">待收货 ({{ numbersData.shipped || 0 }})</el-radio-button>
        <el-radio-button label="4">
          租用中
          <span>({{ numbersData.renting || 0 }})</span>
        </el-radio-button>
        <el-radio-button label="5">
          已结束
          <span>({{ numbersData.completed || 0 }})</span>
        </el-radio-button>
        <el-radio-button label="6">
          已取消
          <span>({{ numbersData.canceled || 0 }})</span>
        </el-radio-button>
      </el-radio-group>
    </div>

    <div class="order-content">
      <div class="content">
        <ul>
          <li v-for="(item, index) in list" :key="index">
            <h2>
              <span>下单时间：{{ item.orderTime }}</span>
              <span>订单编号：{{ item.orderNumber }}</span>
              <span>操作</span>
            </h2>
            <div class="item-line">
              <img :src="item.skuImage" />
              <div class="pro-name">
                <span>
                  {{ item.productName }}
                  <i>x{{ item.commodityNumber }}</i>
                </span>
              </div>
              <div class="pro-price">
                <span>{{ item.propertyShow }}</span>
              </div>
              <div class="pro-monthrent">
                <span>
                  <i v-for="(rent, index) in item.rentalInfoList" :key="index">{{ rent }}</i>
                </span>
              </div>
              <div class="pro-edit">
                <el-link v-if="item.orderState == 8" @click="handleContractOffline(item.orderId)">线下签署</el-link>
                <el-link v-if="item.orderState == 8" @click="getContractUrl(item.orderId)">签署合同</el-link>
                <el-link v-if="item.orderState == 1" @click="payment(item)">租金支付</el-link>
                <!-- <el-link v-if="item.orderState == 4 || item.orderState == 6"  @click="GoPayment(item,1)">租金支付</el-link> -->
                <el-link @click="detailpage(item.orderId)">查看详情</el-link>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div style="text-align:center;">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :currentPage="current" :pageSize="size" background layout="prev, pager, next" :total="total"></el-pagination>
      </div>
    </div>

    <el-dialog title="支付租金" :visible.sync="dialogVisible" width="30%">
      <p>请选择支付租金方式</p>
      <span slot="footer" class="dialog-footer">
        <el-link type="primary" style="margin-right: 20px;" @click="Meetuppayment">线下支付</el-link>
        <el-button size="small" v-if="OfflineName == '线上支付'" type="primary" @click="GoPayment">线上支付</el-button>
        <el-button size="small" v-if="OfflineName == '支付完成'" type="primary" @click="accomplish">支付完成</el-button>
      </span>
    </el-dialog>

    <div id="alipay" style="display:none;"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      current: 1,
      size: 10,
      total: 10,
      dialogVisible: false,
      form: {
        orderQueryState: 0
      },
      date: [],
      list: [],
      numbersData: {},
      paymentPage: '',
      paymeninfo: {},
      OfflineName: '线上支付'
    }
  },
  created() {
    this.getAllOrderList()
    this.request()
  },
  methods: {
    request() {
      this.getOrderList()
    },
    getContractUrl(orderId) {
      this.$axios.post('/zuwu-api/web/contract/getContractUrl', { orderId: orderId }).then((res) => {
        if (res.data.code == 200) {
          window.open(res.data.data.signUrl, '_blank')
        } else {
          this.$alert(res.data.msg, '提示', {
            confirmButtonText: '确定'
          })
        }
      })
    },
    handleContractOffline(orderId) {
      this.$axios.post('/zuwu-api/withhold/offlineSignContract', { orderId: orderId }).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          })
          this.request()
        } else {
          this.$alert(res.data.msg, '提示', {
            confirmButtonText: '确定'
          })
        }
      })
    },
    payment(val) {
      this.OfflineName = '线上支付'
      this.dialogVisible = true
      this.paymeninfo = val
    },
    accomplish() {
      this.dialogVisible = false
      this.getOrderList()
    },
    Meetuppayment() {
      this.$axios.post('/zuwu-api/web/orderPay/applyOfflinePayment/firstRent?orderId=' + this.paymeninfo.orderId).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          })
          this.accomplish()
        } else {
          this.$alert(res.data.msg, '提示', {
            confirmButtonText: '确定'
          })
        }
      })
    },
    GoPayment() {
      let data = {
        payCause: 0,
        orderId: this.paymeninfo.orderId,
        useComputerWebPay: true
      }
      this.$axios.post('/zuwu-api/orderPay/getPayTradeNo', data).then((res) => {
        if (res.data.code == 200) {
          setTimeout(() => {
            this.OfflineName = '支付完成'
          }, 500)
          // this.dialogVisible=true
          this.paymentPage = res.data.data.paymentPage
          this.$nextTick(() => {
            // document.write(this.paymentPage,'_blank')
            //  假设result是后端返回的from字符串
            const newWindow = window.open('', '_blank')
            newWindow.document.write(this.paymentPage)
            newWindow.focus()
          })
          //打开新页面
        } else {
          this.$alert(res.data.msg, '提示', {
            confirmButtonText: '确定'
          })
        }
      })
    },
    detailpage(val) {
      this.$router.push({ path: '/User/order/orderdetail', query: { id: val } })
    },
    getOrderList() {
      const params = {
        current: this.current,
        size: this.size,
        ...this.form
      }

      this.$axios
        .get('/zuwu-api/web/order/list', { params: params })
        .then((res) => {
          this.list = res.data.data.records
          this.total = res.data.data.total
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    getAllOrderList() {
      this.$axios
        .get('/zuwu-api/web/order/count')
        .then((res) => {
          this.numbersData = res.data.data
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    onSearch() {
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if (!this.form.apiUserMobile) {
        this.current = 1
        this.request()
      } else if (!reg.test(this.form.apiUserMobile)) {
        this.$message({
          message: '请填写正确的手机号！'
        })
      } else {
        this.current = 1
        this.request()
      }
    },
    handleExport() {
      this.$axios({
        methods: 'get',
        url: '/zuwu-api/web/installment/exportTenantBill',
        responseType: 'blob'
      })
        .then((response) => response.data)
        .then((blob) => {
          const url = URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.download = '我的账单.xlsx'
          link.click()
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    handleSizeChange(val) {
      this.size = val
      this.request()
    },
    handleCurrentChange(val) {
      this.current = val
      this.request()
    },
    onReset() {
      this.rentDate = []
      this.date = []
      this.current = 1
      this.request()
    }
  }
}
</script>
<style scoped>
.order-herder {
  padding: 20px 10px 0;
  border-bottom: 10px solid #f5f5f5;
}
.order-menu /deep/ .el-radio-group {
  width: 100%;
}
.order-menu /deep/ .el-radio-button {
  width: 14.28%;
}
.order-menu /deep/ .el-radio-button__inner {
  width: 100%;
}
.order-content {
  width: 100%;
}
.content {
  height: auto;
}
.content ul {
  font-size: 0;
  padding: 10px;
}
.content ul li {
  font-size: 12px;
  overflow: hidden;
  margin-bottom: 20px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
}
.content ul li h2 {
  width: 100%;
  font-size: 12px;
  overflow: hidden;
  background: #f3f3f3;
  line-height: 40px;
  color: #333;
}
.content ul li h2 span {
  width: 40%;
  font-weight: normal;
  padding-left: 2%;
  box-sizing: border-box;
  float: left;
}
.content ul li h2 span:nth-of-type(3) {
  width: 20%;
  font-weight: normal;
  box-sizing: border-box;
  float: left;
  text-align: center;
}
.content .item-line {
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  overflow: hidden;
  height: 100px;
}
.content .item-line img {
  width: 80px;
  height: 80px;
  padding: 10px;
  float: left;
  border-right: 1px solid #e0e0e0;
}
.content .item-line .pro-name {
  font-size: 12px;
  width: 260px;
  float: left;
  height: 100%;
  position: relative;
  color: #333;
  border-right: 1px solid #e0e0e0;
}
.content .item-line .pro-name span {
  position: absolute;
  width: 85%;
  left: 5%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 50%;
}

.content .item-line .pro-name span i {
  display: block;
  font-style: normal;
  color: #999;
}
.content .item-line .pro-price {
  width: 260px;
  float: left;
  font-size: 12px;
  height: 100%;
  position: relative;
  border-right: 1px solid #e0e0e0;
  color: #333;
  text-align: center;
}
.content .item-line .pro-price span {
  position: absolute;
  width: 96%;
  left: 2%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 50%;
}
.content .item-line .pro-monthrent {
  border-right: 1px solid #e0e0e0;
  width: 150px;
  float: left;
  text-align: center;
  height: 100%;
  position: relative;
  color: #333;
}
.content .item-line .pro-monthrent span {
  position: absolute;
  width: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  top: 50%;
}
.content .item-line .pro-monthrent span i {
  display: block;
  font-style: normal;
}

.pro-edit {
  text-align: center;
  line-height: 100px;
  font-size: 12px;
}
.pro-edit /deep/ .el-link {
  font-size: 12px;
  margin: 0 5px;
}

.order /deep/ .el-dialog__body {
  padding: 5px 20px;
}
</style>
